import  { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
    {
        path:'/',
        name:'index',
        meta:{
            title:'课程之家-课后服务一站式服务平台',
            keepAlive: true,
			isBack: false,
        },
        component:()=>import('@/components/index.vue')
    },
    {
        path:'/mien',
        name:'mien',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/mien.vue')
    },
    {
        path:'/match',
        name:'match',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/match.vue')
    },
    {
        path:'/hole',
        name:'hole',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/hole.vue')
    },
    {
        path:'/after',
        name:'after',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/after.vue')
    },
    {
        path:'/btm',
        name:'btm',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/btm.vue')
    },
    {
        path:'/news',
        name:'news',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/news.vue')
    },
    {
        path:'/news_details',
        name:'news_details',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/news_details.vue')
    },
    {
        path:'/course',
        name:'course',
        meta:{
            title:'课程之家-课后服务一站式服务平台',
            keepAlive: true,
			isBack: false,
        },
        component:()=>import('@/components/course.vue')
    },
    {
        path:'/course_list',
        name:'course_list',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/course_list.vue')
    },
    {
        path:'/course_details',
        name:'course_details',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/course_details.vue')
    },
    {
        path:'/course_transcript',
        name:'course_transcript',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/course_transcript.vue')
    },
    {
        path:'/course_details_transcript',
        name:'course_details_transcript',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/course_details_transcript.vue')
    },
    {
        path:'/course_list_transcript',
        name:'course_list_transcript',
        meta:{
            title:'课程之家-课后服务一站式服务平台'
        },
        component:()=>import('@/components/course_list_transcript.vue')
    },
]

const router = createRouter({
    history:createWebHashHistory(),
    routes,
    scrollBehavior (to,from,savedPosition) {
        if(savedPosition){
            return savedPosition
        }else{
            return { left: 0, top: 0 }
        }
      }
})

export default router;