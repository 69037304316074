/**
 * 引入POST、BASE_URL
 * @param params
 * @returns {*}
 */
import { POST, BASE_URL, BASE_URL1} from '../api/index'
export const urls =  "https://api.kechengzhijia.com"//接口域名地址
// export const urls =  "http://api.test.51qr.net"//接口域名地址
//官网课程列表
export function get_sitebigcourse_list(params) {
	return POST(urls + '/site/get_sitebigcourse_list', params, 'post', '')
}
//官网资讯列表
export function get_realtime_list(params) {
	return POST(urls + '/Site/get_realtime_list', params, 'post', '')
}
//官网课程记录
export function get_square_list(params) {
	return POST(urls + '/Site/get_square_list', params, 'post', '')
}
//banner
export function get_banner_list(params) {
	return POST(urls + '/Site/get_banner_list', params, 'post', '')
}
//活动赛事
export function get_active_list(params) {
	return POST(urls + '/Site/get_active_list', params, 'post', '')
}
//产品服务
export function get_realtime_detail(params) {
	return POST(urls + '/Site/get_realtime_detail', params, 'post', '')
}
//资讯类型
export function get_realtime_type_list(params) {
	return POST(urls + '/Site/get_realtime_type_list', params, 'post', '')
}
//资讯上下一篇
export function get_realtime_prev_next(params) {
	return POST(urls + '/Site/get_realtime_prev_next', params, 'post', '')
}
//课程类型
export function get_course_type(params) {
	return POST(urls + '/Seller/get_course_type', params, 'post', '')
}
//获取官网大课下的课程列表
export function get_sitebigcourse_detail_list(params) {
	return POST(urls + '/site/get_sitebigcourse_detail_list', params, 'post', '')
}
//课程详情
export function get_site_course_info(params) {
	return POST(urls + '/Site/get_site_course_info', params, 'post', '')
}
//提交信息
export function save_feedback(params) {
	return POST(urls + '/Site/save_feedback', params, 'post', '')
}


