
import {
  get_realtime_list,
} from "./api/home";
import {
  ref,
  reactive,
  onMounted
} from "vue";
export default {
  name: 'App',
  data() {
    return {
      topBannerNavBg: {
        backgroundColor: ''
      },
      topBannerNavBg1: {
        backgroundColor: '#fff'
      },
      topcolor: {
        color: ''
      },
      topcolor1: {
        color: '#333'
      },
      scroll: 0,
      yuming: 1,
      beian:2
    }
  },
  setup() {
    const course = reactive({
      value: [],
    })
    const methods = {
      get_realtime_list() {
        get_realtime_list()
          .then(res => {
            for (var i = 0; i < res.data.rtdata.length; i++) {
              if (i < 3) {
                course.value.push(res.data.rtdata[i])
              }
            }
          })
          .catch(error => {
            console.log('获取失败！')
          });
      }
    }
    onMounted(() => {
      methods.get_realtime_list()
    })
    return { course, methods };
  },
  mounted() {
    if (this.$route.path == '/') {
      window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    }
    let urlkeui = window.location.href;
    if (urlkeui.indexOf("xinxiang") > -1) {
      //判断url地址中是否包含link字符串
      this.yuming = 2;
    }
    if (urlkeui.indexOf("kechengzhijia") > -1) {
      //判断url地址中是否包含link字符串
      this.beian = 2;
    }
    if (urlkeui.indexOf("51qr") > -1) {
      //判断url地址中是否包含link字符串
      this.beian = 1;
    }
  },
  methods: {
    details(s){
      this.$router.push({
                    path: "/news_details",
                    query: {
                        art_id: s.art_id,
                    }
                })
            },
    qies() {
      this.$router.push({
        path: '/',
      });
    },
    log() {
      if (this.yuming == 1) {
        window.open("http://admin.kechengzhijia.com/#/login", "_blank");
      } else {
        window.open("http://xinxianggl.kechengzhijia.com/#/login", "_blank");
      }
    },
    news() {
      this.$router.push({
        path: '/news',
      });
    },
    btms(s, d) {
      if (this.$route.path == '/btm') {
        this.$router.replace({
          path: "/btm",
          query: {
            index: s,
            art_num: d
          }
        })
      } else {
        this.$router.push({
          path: '/btm',
          query: {
            index: s,
            art_num: d
          }
        });
      }
    },
    route(s) {
      this.$router.push({
        path: s,
      });
    },
    // 滚动页面时触发导航变色
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 设置背景颜色的透明度
      if (scrollTop > 20 && scrollTop < 50) {
        this.topBannerNavBg.backgroundColor = 'rgb(255 255 255 / 20%)'
        this.topcolor.color = 'rgb(255 255 255 / 100%)'
        this.scroll = 0
      } else if (scrollTop > 50 && scrollTop < 100) {
        this.topBannerNavBg.backgroundColor = 'rgb(255 255 255 / 40%)'
        this.topcolor.color = 'rgb(51 51 51 / 80%)'
        this.scroll = 0
      } else if (scrollTop === 0) {
        this.topBannerNavBg.backgroundColor = 'transparent' // 设置回到顶部时，背景颜色为透明
        this.topcolor.color = 'rgb(255 255 255 / 100%)'
        this.scroll = 0
      } else if (scrollTop > 100 && scrollTop < 200) {
        this.topBannerNavBg.backgroundColor = 'rgb(255 255 255 / 60%)'
        this.scroll = 0
      } else if (scrollTop > 200 && scrollTop < 300) {
        this.topBannerNavBg.backgroundColor = 'rgb(255 255 255 / 80%)'
        this.scroll = 0
      } else if (scrollTop > 300) {
        this.topBannerNavBg.backgroundColor = 'rgb(255 255 255 / 100%)'
        this.topcolor.color = 'rgb(51 51 51 / 100%)'
        this.scroll = 1
      }
    },
    // 滚动之前重置
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll)
    },
  }
}
