import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import './assets/css/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面meta */
	/* 路由发生变化修改页面title */
	// let url = window.location.href;
	// if (url.indexOf("51qr") > -1) {
	// 	//判断url地址中是否包含link字符串
	// 	document.title = "河南学明信息科技有限公司网站";
	// }
	// if (url.indexOf("kechengzhijia") > -1) {
	// 	//判断url地址中是否包含link字符串
	// 	document.title = "河南学明信息科技有限公司";
	// }
	if(to.meta.title) {
		document.title = typeof(to.meta.title) === "string" ? to.meta.title : "课程之家";
	}
	next()
});
let app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}
